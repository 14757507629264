/*====================================
	1. Color
====================================*/
.path-info .article {
  max-width: 1000px;
  margin: 20px auto 80px;
}

.path-info .article .info-wrap {
  border-bottom: 2px solid #E6E6E6;
  letter-spacing: 0.05em;
  line-height: 1.75;
}

.path-info .article .info-wrap a {
  display: block;
  color: #121212;
  font-weight: 400;
  padding: 24px 40px 24px 16px;
  position: relative;
}

.path-info .article .info-wrap a .txt {
  margin-bottom: 4px;
}

.path-info .article .info-wrap a .date {
  text-align: right;
  color: #8D8D8D;
}

.path-info .article .info-wrap a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 24px;
  right: 12px;
  width: 21px;
  height: 10px;
  border-right: 3px solid #DDDDDD;
  border-bottom: 2.5px solid #DDDDDD;
  transform: translateY(-50%) skew(45deg, 0);
  margin-top: 8px;
  margin-left: 16px;
  transition: .3s;
}

/****************************************************
 PC scss
****************************************************/
@media screen and (min-width: 768px) {
  .path-info .article .info-wrap a {
    padding: 24px 40px 24px 16px;
    transition: .3s;
  }
  .path-info .article .info-wrap a:hover {
    background: #F5F5F5;
    transition: .3s;
    position: relative;
  }
  .path-info .article .info-wrap a:hover::after {
    transition: .3s;
    right: 4px;
  }
}
