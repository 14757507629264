@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";
.path-info {
  .article {
    max-width: 1000px;
    margin: 20px auto 80px;
    .info-wrap {
      border-bottom: 2px solid $GRAY_200;
      letter-spacing: 0.05em;
      line-height: 1.75;
      a {
        display: block;
        color: $GRAY_700;
        font-weight: 400;
        padding: 24px 40px 24px 16px;
        .txt {
          margin-bottom: 4px;
        }
        .date {
          text-align: right;
          color: $GRAY_500;
        }
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 24px;
          right: 12px;
          width: 21px;
          height: 10px;
          border-right: 3px solid $GRAY_300;
          border-bottom: 2.5px solid $GRAY_300;
          transform: translateY(-50%) skew(45deg, 0);
          margin-top: 8px;
          margin-left: 16px;
          transition: .3s;
        }
      }
    }
  }
}







/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
  .path-info {
    .article {
      .info-wrap {
        a {
          padding: 24px 40px 24px 16px;
          transition: .3s;
          &:hover {
            background: $GRAY_100;
            transition: .3s;
            position: relative;
            &::after {
              transition: .3s;
              right: 4px;
            }
          }
        }
      }
    }
  }
}
